@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'kanit';
    src: url(./Kanit-SemiBold.ttf);
}

@font-face {
    font-family: 'koulen';
    src: url(./Koulen-Regular.ttf);
}

html,
body {
    scroll-behavior: smooth;
    overflow-x: clip;
}


body {
    --sb-track-color: #232E33;
    --sb-thumb-color: #ffffff;
    --sb-size: 10px;

    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

body::-webkit-scrollbar {
    width: var(--sb-size)
}

body::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;

}



.nav__li {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }
  
  .nav__li::before,
  .nav__li::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
  }
  .nav__li::before {
    background-color: #EC1C1C;
    height: 2px;
    bottom: 3;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  .nav__li:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }